import Vue from "vue";
import VueRouter from "vue-router";
import Auth from "../utils/Auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () =>
      import("../views/Login.vue" /* webpackChunkName: "Login" */),
  },
  {
    path: "/",
    name: "Home",
    redirect: "/pina",
    component: () =>
      import(
        "../components/NavigationSidebar.vue" /* webpackChunkName: "Pina" */
      ),
    children: [
 /*     {
        path: "/Sop-Gesamt",
        name: "SOP-Gesamtübersicht",
        component: () =>
          import(
            "../views/Sop-Gesamt.vue" /* webpackChunkName: "SOP-Gesamt" */
      {
        path: "pina",
        redirect: "pina/dashboard",
        component: () =>
          import("../components/HeaderTabs.vue" /* webpackChunkName: "Pina" */),
        children: [
          {
            path: "dashboard",
            name: "Dashboard",
            component: () =>
              import("../views/Pina.vue" /* webpackChunkName: "Pina" */),
          },
          {
            path: "documents",
            name: "Dokumente",
            component: () =>
              import("../views/Dokumente.vue" /* webpackChunkName: "Pina" */),
          },
          {
            path: "standards",
            name: "Standards",
            component: () =>
              import("../views/Standards.vue" /* webpackChunkName: "Pina" */),
          },
          {
            path: "drugs",
            name: "Medikamente",
            component: () =>
              import("../views/Medikamente.vue" /* webpackChunkName: "Pina" */),
          },
          {
            path: "onboarding",
            name: "Onboarding",
            component: () =>
              import(
                "../views/Onboarding.vue" /* webpackChunkName: "Onboarding" */
              ),
          },
          {
            path: "training",
            name: "Fortbildungen",
            component: () =>
              import(
                "../views/Fortbildungen.vue" /* webpackChunkName: "Login" */
              ),
            meta: { transition: "slide-left" },
          },
        ],
      },
    ],
  },
/*/
  {
    path: "/organisation",
    name: "Organisation",
    component: () =>
      import(
        "../views/Organisation.vue"  webpackChunkName: "Organisation"
      ),
  },
  {
    path: "/adminarea",
    name: "AdminArea",
    component: () => import("../views/AdminArea.vue"),
  },
  {
    path: "/e-learning",
    name: "E-Learning",
    component: () =>
      import("../views/E-Learning.vue" /* webpackChunkName: "E-Learning" ),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import("../views/Profile.vue" /* webpackChunkName: "E-Learning" ),
  },
  {
    path: "/dienstplan",
    name: "Dienstplan",
    component: () =>
      import("../views/Dienstplan.vue" /* webpackChunkName: "Dienstplan" ),
  },
*/
   {
   path: "/%23/",
     redirect: "/",
   },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("../views/404.vue"),
  },
];
const router = new VueRouter({ mode: "history", routes });

router.beforeEach(async (to, from, next) => {
  const authenticated = Auth.isAuthed();
  if (to.name !== "Login" && !authenticated) {
    console.error("Unauthenticated");
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
