import {
  fetchCategories,
  fetchStandards,
  fetchStandardsByCategory,
  me,
} from "../../api";

const state = () => ({
  user: null,
  categories: null,
  standards: null,
  categoryToStandardMap: null,
  alerts: null,
  cards: null,
  lastFetchedAt: null,
});

const getters = {
  getCategoryNames(state) {
    if (!state.categories) {
      return [];
    }
    return state.categories.data.map(category => category.attributes.name);
  },
};

const actions = {
  async getCategories({ commit }) {
    const categories = await fetchCategories();
    commit("SET_CATEGORIES", categories);
  },
  async getMe({ commit }) {
    const user = await me();
    commit("SET_USER", user);
  },
  async getStandards({ commit }) {
    const standards = await fetchStandards();
    commit("SET_STANDARDS", standards);
  },
  async getCategoryToStandardMap({ commit }) {
    const categoryToStandardMap = {};
    const categories = await fetchCategories();
    for (const category of categories.data) {
      const categoryName = category.attributes.name;
      categoryToStandardMap[categoryName] = await fetchStandardsByCategory(
        categoryName,
      );
    }
    commit("SET_CATEGORY_TO_STANDARD_MAP", categoryToStandardMap);
  },
};

const mutations = {
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_STANDARDS(state, standards) {
    state.standards = standards;
  },
  SET_CATEGORY_TO_STANDARD_MAP(state, categoryToStandardMap) {
    state.categoryToStandardMap = categoryToStandardMap;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
