<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  methods: mapActions("strapi", ["getCategories", "getStandards"]),
  created() {
    // this.$store.dispatch("strapi/getCategories");
    // this.$store.dispatch("strapi/getStandards");
    // this.$store.dispatch("strapi/getCategoryToStandardMap");
  },
  // hides scroll bar on main frame
  mounted: function() {
    let elHtml = document.getElementsByTagName("html")[0];
    elHtml.style.overflowY = "auto";
  },
  destroyed: function() {
    let elHtml = document.getElementsByTagName("html")[0];
    elHtml.style.overflowY = null;
  },
};
</script>

<style lang="scss">
@import "./style/all.css";
@import "./style/fonts";
/*@import "./style/tutorial"; */

/*
################################################################################
               THEMING
################################################################################
*/
:root {
  --background-color-primary: #171e29;
  --background-color-primary-light: #f5f5f5;
  --background-color-secondary: #222c3c;
  --accent-color: #8eacc5;
  --text-light-color: #333;
  --text-dark-color: #ddd;
  --header-light-color: var(--background-color-primary-light);
  --border-color: #313d4f;
  --table-border-color: #8eacc554;
  --highlight-color: teal;
  --border-color-light: #e5e5e5;
}
body {
  background: #e5e7eb;
  -webkit-font-smoothing: subpixel-antialiased;
}
.lstight {
  letter-spacing: -0.025em !important;
}

.theme--dark .v-expansion-panel-header {
  color: var(--text-dark-color) !important;
}
.theme--light.v-expansion-panels .v-expansion-panel {
  background: var(--background-color-primary-light) !important;
}

.theme--light .v-application {
  background: red !important;
  height: 100% !important;
}
.theme--dark.v-application {
  background: var(--background-color-primary) !important;
}
.theme--light.v-application {
  background: #e5e7eb !important;
}
.v-sheet {
  background: var(--background-color-primary-light) !important;
  overflow: hidden !important;
}
.theme--dark .v-card .sidebar-card {
  background: var(--background-color-secondary) !important;
}
.theme--light .v-sheet .newstile {
  background: white !important;
}
.theme--dark .v-sheet .newstile {
  background: var(--background-color-secondary) !important;
  border-bottom: 1px solid var(--border-color) !important;
}
.theme--dark .v-sheet .newstile:hover {
  background: #ffffff10 !important;
}
.theme--dark .v-sheet {
  background: none !important;
}
.theme--dark .dashboard-card.v-card {
  background: var(--background-color-secondary) !important;
}
.theme--dark .v-data-table {
  background: var(--background-color-secondary) !important;
}
.theme--dark .pinamedtable.v-data-table td {
  border-bottom: thin solid var(--table-border-color) !important;
  border-left: thin solid var(--table-border-color) !important;
}
.theme--dark .documentssmall.v-data-table td {
  border-bottom: thin solid var(--table-border-color) !important;
  border-left: thin solid var(--table-border-color) !important;
}
.theme--dark .documentssmall.v-data-table tr:nth-child(2n) {
  background-color: #f6f8fa10 !important;
  border-left: 0px !important;
}
.theme--dark .documentssmall.v-data-table {
  border-left: 0px solid red !important;
}
.theme--dark .component-bg {
  background: var(--background-color-secondary) !important;
}
.theme--dark .searchInput {
  border: 1px solid var(--border-color) !important;
}
.theme--dark .searchfilter {
  background: none !important;
  border-left: 1px solid var(--border-color) !important;
  color: var(--text-dark-color);
}
.theme--dark .v-btn.darkmodetoggle {
  background: var(--background-color-primary) !important;
  color: var(--accent-color) !important;
}
.theme--light .v-btn.darkmodetoggle {
  background: #fff !important;
  color: var(--text-light-color) !important;
}
.theme--dark svg.togglesvg {
  fill: var(--accent-color);
  transition: cubic-bezier(0.895, 0.03, 0.685, 0.72);
}
.theme--light svg.togglesvg {
  fill: #fb8c00 !important;
}
.theme--dark .menubutton {
  background: var(--background-color-primary) !important;
  color: var(--accent-color) !important;
  border: 1px solid var(--border-color) !important;
}
.theme--dark .v-btn.darkmodetoggle {
  background: var(--background-color-primary) !important;
  border: 1px solid var(--border-color) !important;
}
.theme--light .v-btn.darkmodetoggle {
  background: white !important;
  border: none !important;
}
.theme--dark.v-application {
  background: var(--background-color-primary) !important;
}
.v-navigation-drawer.v-navigation-drawer--fixed.v-navigation-drawer--open.theme--dark {
  background: var(--background-color-primary);
}
//.theme--light .v-input:not(.v-input--checkbox) {
//  border: 1px solid var(--border-color) !important;
//}
.theme--dark.v-expansion-panels .v-expansion-panel {
  background: var(--background-color-secondary) !important;
}
.theme--dark.v-card.dashboard-card {
  background: var(--background-color-secondary) !important;
}
.theme--dark.v-application p {
  color: var(--text-dark-color) !important;
}
.theme--dark.v-application a {
  color: var(--text-dark-color) !important;
}
.theme--dark .v-list-item__title {
  color: var(--accent-color) !important;
}
.theme--dark.dark-border {
  border: 1px solid var(--border-color) !important;
}
.v-image.v-responsive.app-logo.theme--dark {
  filter: brightness(0) invert(1);
}
.theme--dark .v-list-item__title.app-title {
  color: white !important;
}
.theme--light .subtitle-styles {
  color: var(--header-light-color) !important;
}
.theme--dark .subtitle-styles {
  color: var(--text-dark-color);
}
.theme--dark .v-subheader {
  color: var(--accent-color) !important;
}
.theme--dark .v-subheader.nav-subheader {
  color: var(--border-color) !important;
}
.theme--dark .v-card .app-news {
  color: var(--accent-color) !important;
}
.theme--dark .calendar-icon {
  color: var(--accent-color) !important;
}
.theme--dark p::selection {
  background: rgb(235, 235, 174) !important;
  color: black;
}
.theme--light .v-list .pa-0 .nav-bg .v-sheet {
  background: white !important;
  color: blue;
}
/*
################################################################################
               GLOBAL STYLES
################################################################################
------------------------------------------------------------------
              TYPOGRAPHY
------------------------------------------------------------------
*/
h1 {
  line-height: 3rem;
  margin-bottom: 1rem;
  font-size: 3.5rem;
  font-weight: 600;
  letter-spacing: -0.8;
}
h2 {
  font-size: 1.3rem;
  padding: 0.5rem 0;
  font-weight: 300;
}
h3 {
  margin-bottom: 1.3rem;
  font-size: 1.5rem;
}
h4 {
  font-size: 1.6em;
  font-weight: 600;
}
h5 {
  font-size: 1.8rem;
  padding-bottom: 1rem;
  font-weight: 300;
}
p::selection {
  background: rgba(255, 253, 150, 0.99);
}
a {
  display: inline-block;
  text-decoration: none;
}

@media (min-width: 960px) {
  .container {
    min-width: 100% !important;
  }
}
.v-application p {
  margin-bottom: 0 !important;
  color: unset !important;
  background: inherit;
}
.v-application a {
  color: unset !important;
  margin: 0;
}
.v-application a:hover {
  transform: scale(1.02);
  transition: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  transition-duration: 0.05s;
}
/*
------------------------------------------------------------------
              MISC
------------------------------------------------------------------
Hide SCROLLBAR
*/
::-webkit-scrollbar {
  display: none;
}
.honto-shadow {
    box-shadow: 0 8px 5px rgb(0 0 0 / 1%), 0 12px 12px rgb(0 0 0 / 1%)!important;
}
.honto-border {
  //  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  //    0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035) !important;
  border: 1px solid var(--border-color) !important;
  border-radius: 6px !important;
}
.theme--light .honto-border {
  //  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  //    0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035) !important;
  border: 1px solid var(--border-color-light) !important;
  border-radius: 6px !important;
}
.theme--dark .honto-border {
  //  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  //    0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035) !important;
  border: 1px solid var(--border-color) !important;
  border-radius: 6px !important;
}

.v-tooltip {
  opacity: 1 !important;
}

.subpage {
  position: relative !important;
  animation: animation 0.33s ease-in-out !important;
}

.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: all 250ms ease-in-out;
}
.maincontainer {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px !important;
  margin-top: -6rem !important;
  overflow: hidden !important;
}
.tablecontainer {
  margin: 0 -0.5rem;
  border-radius: 8px;
  background: var(--background-color-primary-light) !important;
  border: 0px solid #d7d7d7;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
}
.bg-card {
  position: relative; 
}
.bg-card::before  {
  content: "";
  position: absolute;
      -webkit-box-pack: center !important;
    justify-content: center !important;
    background-repeat: no-repeat !important;
    background-position: center top !important;
    background-image: url("https://keynote.tailwindui.com/_next/static/media/background.6c3571e0.jpg")!important;
    background-size: cover !important;
    top: -300px;
      right: 50px;
      bottom: 0px!important;
      left: 0px;
      opacity: .4;
      overflow: hidden!important;
} 
.v-text-field > .v-input__control > .v-input__slot {
    border: 1px solid rgb(51 51 51 / 15%)!important;
  }
.v-dialog {
  border-radius:14px!important;
}
</style>
