import Vuex from "vuex";
import Vue from "vue";
import strapi from "./modules/strapi";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    strapi,
  },
});
