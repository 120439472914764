import router from "../router";
import Auth from "../utils/Auth";
import { getCookie } from "../utils/CookieHelper";

const moment = require("moment");

export const FetchMethod = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  patch: "PATCH",
};

export default class FetchService {
  static baseHeaders() {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  }

  static authHeaders() {
    const token = getCookie("jwt");
    if (!token) {
      return {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    }
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + getCookie("jwt"),
    };
  }

  static applyParameters(parameters, url) {
    var urli = url;
    if (parameters) {
      for (var key in parameters) {
        if (
          parameters[key] ||
          parameters[key] === 0 ||
          parameters[key] === false
        ) {
          if (urli.includes("?")) {
            urli += "&" + FetchService.applyParameter(parameters, key);
          } else {
            urli += "?" + FetchService.applyParameter(parameters, key);
          }
        }
      }
    }
    return urli;
  }

  static convertParameter(parameter) {
    if (parameter instanceof moment) {
      return parameter.toISOString();
    }

    if (parameter instanceof Date) {
      return parameter.toISOString();
    }

    return encodeURIComponent(parameter);
  }

  static wrapParameter(parameter, key) {
    return key + "=" + FetchService.convertParameter(parameter);
  }

  static applyParameter(parameters, key) {
    if (Array.isArray(parameters[key])) {
      return parameters[key]
        .map((item, idx) => FetchService.wrapParameter(item, `${key}[${idx}]`))
        .join("&");
    }
    return FetchService.wrapParameter(parameters[key], key);
  }

  static async fetchApi(
    method,
    url,
    payload,
    headers,
    ignoreResult,
    abortSignal,
  ) {
    let options = {
      method: method,
    };

    if (abortSignal) {
      options.signal = abortSignal;
    }

    if (headers) {
      options.headers = headers;
    } else {
      options.headers = FetchService.authHeaders();
    }

    if (payload) {
      if (
        options.headers &&
        options.headers["Content-Type"] === "application/json"
      ) {
        options.body = JSON.stringify(payload);
      } else {
        options.body = payload;
      }
    }
    let response = await fetch(url, options);

    if (response.status < 200 || response.status >= 400) {
      if (response.status === 401) {
        // unauthenticated
        Auth.get().logout();
        router.push("/login");
      }
      let json = await response.json();
      throw new Error(json.error.message, { cause: json.error });
    }

    if (!ignoreResult) {
      let json = await response.json();
      return json;
    }
  }
}
