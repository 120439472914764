export const setCookie = (cname, cvalue, exdays, domain) => {
  let d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();

  let cookieString = cname + "=" + cvalue + ";" + expires + ";path=/";
  if (domain) {
    cookieString += ";domain=" + domain;
  }

  document.cookie = cookieString;
};

export const setDomainWideCookie = (cname, cvalue, exdays) => {
  /*
        Glue code in order to migrate already created cookies with domain=dashboard.vivenu.com
        which cannot be overwritten by setCookie with domain=vivenu.com
        */
  setCookie(cname, "", -10);
  const domain = location.hostname.replace("www", "");
  return setCookie(cname, cvalue, exdays, domain);
};

export const getCookie = cname => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return undefined;
};