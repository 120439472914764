import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import de from "vuetify/lib/locale/de";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { de },
    current: "de",
  },
  theme: {
    themes: {
      light: {
        componentBg: "#ffffff",
        secondary: colors.deepOrange.lighten1
      },
      dark: {
        componentBg:"#222c3c",
        secondary: "red"
      }
    }
  }
});
