import { CMS_URL } from "../api";
import FetchService, { FetchMethod } from "../api/FetchService";
import { getCookie, setDomainWideCookie } from "./CookieHelper";

export default class Auth {
  expired = false;
  authed = false;

  static instance = null;

  constructor() {
    this.authed = Auth.isAuthed();
  }

  static isAuthed() {
    return !!getCookie("jwt");
  }

  static get() {
    if (!Auth.instance) {
      Auth.instance = new Auth();
    }
    return Auth.instance;
  }

  logout() {
    if (typeof window !== "undefined") {
      this.authed = false;
      this.setToken("");
      window.location.reload();
    }
  }

  async login(username, password) {
    const payload = {
      identifier: username,
      password: password,
    };
    try {
      const res = await FetchService.fetchApi(
        FetchMethod.post,
        CMS_URL + "auth/local",
        payload,
        FetchService.baseHeaders(),
      );
      this.authed = true;
      Auth.get().setToken(res.jwt);
    } catch (e) {
      console.log(e);
      if (e.cause?.status === 400) {
        this.invalidCredentials = true;
      }
    }
  }

  setToken(token) {
    setDomainWideCookie("jwt", token, 30);
  }
}
