import FetchService, { FetchMethod } from "./FetchService";
const qs = require("qs");
export const CMS_URL = "https://admin.honto-health.com/api/";

export const me = async () => {
  return await FetchService.fetchApi(
    FetchMethod.get,
    CMS_URL + "users/me?populate[0]=userimage&populate[1]=role",
  );
};

export const fetchResource = async (resourceName, queryParams) => {
  let query = queryParams || "";
  if (typeof query === "object") {
    query = qs.stringify(query, { encodeValuesOnly: true });
  }
  const input = `${CMS_URL}${resourceName}?${query}`;
  return await FetchService.fetchApi(FetchMethod.get, input);
};

export const fetchCategories = async queryParams => {
  const query =
    queryParams ||
    qs.stringify(
      {
        populate: { standards: { populate: "file" } },
      },
      { encodeValuesOnly: true },
    );
  return await fetchResource("categories", query);
};

export const fetchStandards = async queryParams => {
  const query =
    queryParams ||
    qs.stringify(
      {
        populate: { populate: "file", sort: ["name"] },
      },
      { encodeValuesOnly: true },
    );
  return await fetchResource("standards", query);
};

export const fetchStandardsByCategory = async category => {
  const query = qs.stringify(
    {
      filters: {
        category: {
          name: { $eq: category },
        },
      },
      populate: ["file", "category"],
      sort: ["name"],
      pagination: {
        pageSize: 1000,
      },
    },
    { encodeValuesOnly: true },
  );
  return await fetchResource("standards", query);
};
